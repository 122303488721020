import type { ObservationSite } from '@soilsense/shared';
import { FARM_DECODER } from '@soilsense/shared';
import type { DocumentReference, Transaction } from 'firebase/firestore';
import {
  addDoc,
  arrayUnion,
  deleteField,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import type { IFarmSettings } from '../components/Settings/settingsState';
import { farmSettingsToFirebaseObj } from '../components/Settings/settingsState';
import type { FieldWithId } from '../interfaces/Field';
import type { IFarm, IFarmWithoutId } from '../interfaces/IFarm';
import { stripUndefinedFromObj } from '../utils/stripUndefinedFromObj';
import type FirebaseApi from './FirebaseApi';

export interface IConfirmation<T> {
  id: string;
  data: T;
}

class Farms {
  constructor(private readonly api: FirebaseApi) {}

  private getFarmDocRef(id: string): DocumentReference {
    return doc(this.api.getCollections().farms(), id);
  }

  public async getFarm(id: string): Promise<IFarm> {
    const d = await getDoc(this.getFarmDocRef(id));
    const data = await FARM_DECODER.decodeToPromise(d.data());
    return {
      id: d.id,
      ...data,
    };
  }

  public async getAllFarms(): Promise<IFarm[]> {
    const snapshot = await getDocs(this.api.getCollections().farms());
    return Promise.all(
      snapshot.docs.map(async (doc) => {
        const data = await FARM_DECODER.decodeToPromise(doc.data());
        return { ...data, id: doc.id };
      })
    );
  }
  public async addFarm(name: string, ownerId: string): Promise<IConfirmation<IFarmWithoutId> | undefined> {
    return addDoc(this.api.getCollections().farms(), {
      name,
      ownerId,
      readers: [ownerId],
      events: {
        '0': {
          rule: 'over-irrigation',
        },
        '1': {
          rule: 'stress',
        },
      },
      created: serverTimestamp(),
    })
      .then(async (farmRef) => {
        const farm = await getDoc(farmRef);
        const data = await FARM_DECODER.decodeToPromise(farm.data());
        return {
          id: farm.id,
          data: data,
        };
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
        return undefined;
      });
  }

  public async updateFarmSettings(farmId: string, farmSettings: IFarmSettings): Promise<void> {
    const farmDoc = this.api.getDocRef(`farms/${farmId}`);
    const { country } = farmSettings;
    const updates = {
      ...stripUndefinedFromObj(farmSettingsToFirebaseObj(farmSettings)),
      country: country == undefined ? deleteField() : country,
    };
    await updateDoc(farmDoc, updates);
  }

  public generateNewId(): string {
    return doc(this.api.getCollections().farms()).id;
  }

  public updateObservationSites(
    farmId: string,
    updates: readonly (readonly [string, ObservationSite])[],
    transaction: Transaction
  ): void {
    const observationSites = Object.fromEntries(
      updates.map(([id, site]) => [
        `observationSites.${id}`,
        {
          ...site,
          soilDataSourceHistory: site.soilDataSourceHistory.map(({ startTimestamp, value }) => ({
            startTimestamp: Timestamp.fromMillis(startTimestamp),
            value: value,
          })),
        },
      ])
    );
    const observationSiteOrder = arrayUnion(...updates.map(([id]) => id));

    transaction.update(this.getFarmDocRef(farmId), {
      ...observationSites,
      observationSiteOrder,
    });
  }

  public async setSiteAndFieldOrder(farmId: string, siteAndFieldOrder: readonly string[]): Promise<void> {
    await updateDoc(this.getFarmDocRef(farmId), {
      siteAndFieldOrder,
    });
  }

  public async updateField(farmId: string, field: FieldWithId): Promise<void> {
    const { id, ...fieldDocument } = field;
    await updateDoc(this.getFarmDocRef(farmId), {
      [`fields.${id}`]: fieldDocument,
    });
  }

  public async deleteField(farmId: string, field: FieldWithId): Promise<void> {
    const { id } = field;
    await updateDoc(this.getFarmDocRef(farmId), {
      [`fields.${id}`]: deleteField(),
    });
  }
}

export default Farms;
