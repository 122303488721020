import type { UserCredential } from '@firebase/auth';
import { setDoc } from '@firebase/firestore';
import type { MessagePayload } from '@firebase/messaging';
import { action, computed, makeObservable, observable } from 'mobx';
import moment from 'moment';
import type { IUserSettings } from '../components/Settings/settingsState';
import type { Base } from '../firebase/base';
import { LOCALES, ourLocaleToMomentLocale } from '../i18n/locales';
import type { FirebaseTokenProvider, User, UserSettings } from '../interfaces/IUser';
import { loadFromLocalStorage, saveToLocalStorage } from './utils/localStorage';

export type UserUpdate = Readonly<{
  user: User;
  tokenProvider: FirebaseTokenProvider;
}>;

interface IFarmPermission {
  id: string;
  isAdmin: boolean;
}

export class UserStore {
  // `undefined` signifies that the logged in user is currently unknown
  // `null` signifies that no user is currently logged in
  @observable private user: User | null | undefined;
  private tokenProvider: FirebaseTokenProvider | undefined;
  private serviceWorkerRegistration: ServiceWorkerRegistration | undefined;
  private isMobile = false;
  @observable locale: LOCALES = LOCALES.ENGLISH;

  constructor(private readonly firebase: Base) {
    makeObservable(this);

    const rememberedLocale = loadFromLocalStorage('locale') as LOCALES;
    if (rememberedLocale && Object.values(LOCALES).includes(rememberedLocale)) {
      this.updateLocale(rememberedLocale);
    } else {
      this.updateLocale(LOCALES.ENGLISH);
    }
  }

  @computed get currentUser(): User | null | undefined {
    return this.user;
  }

  get currentTokenProvider(): FirebaseTokenProvider | undefined {
    return this.tokenProvider;
  }

  @action updateLocale(locale: typeof LOCALES[keyof typeof LOCALES]): void {
    moment.locale(ourLocaleToMomentLocale(locale));
    saveToLocalStorage('locale', locale);
    this.locale = locale;
  }

  @action updateUserSettings(settings: Partial<UserSettings>): void {
    if (this.user != undefined) {
      this.user = {
        ...this.user,
        ...settings,
      };
    }
  }

  @action setUser(update: UserUpdate | null): void {
    if (update == null) {
      this.user = null;
      this.tokenProvider = undefined;
    } else {
      this.user = update.user;
      this.tokenProvider = update.tokenProvider;
      this.updateMessagingToken();
    }
  }

  @action setServiceWorkerRegistration(registration: ServiceWorkerRegistration, isMobile: boolean): void {
    this.serviceWorkerRegistration = registration;
    this.isMobile = isMobile;
    this.updateMessagingToken();
  }

  async logOut(): Promise<void> {
    await this.firebase.auth.logOut();
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    await this.firebase.auth.sendPasswordResetEmail(email);
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<void> {
    await this.firebase.auth.signInWithEmailAndPassword(email, password);
  }

  async createUser(userId: string, email: string, farmPermissions: readonly IFarmPermission[]): Promise<void> {
    await setDoc(this.firebase.auth.getUser(userId), {
      email,
      farmPermissions,
      pawSwitchAvailable: true,
      isAdmin: false,
    });
  }

  public async createUserWithEmailAndPassword(
    email: string,
    password: string,
    initializeUser: (authUser: UserCredential) => Promise<void>
  ): Promise<void> {
    await this.firebase.auth.createUserWithEmailAndPassword(email, password, initializeUser);
  }

  // fetch and save user's messaging token if they're accessing
  // the app from a mobile device
  private async updateMessagingToken(): Promise<void> {
    if (this.isMobile && this.user != undefined && this.serviceWorkerRegistration != undefined) {
      try {
        const firebaseToken = await this.firebase.messaging.requestFirebaseNotificationToken(
          this.serviceWorkerRegistration
        );
        if (firebaseToken != undefined && firebaseToken != this.user.messagingToken) {
          await this.firebase.users.saveUserMessagingToken(this.user.uid, firebaseToken);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  onMessage(listener: (payload: MessagePayload) => void): () => void {
    return this.firebase.messaging.onMessage(listener);
  }

  async doUpdateUserSettings(userId: string, userSettings: IUserSettings): Promise<void> {
    await this.firebase.users.updateUserSettings(userId, userSettings);
  }
}
